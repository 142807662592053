<template>
  <master-layout>
    <div class="mb-3 text-right">
      <div class="flex-md-row d-sm-flex justify-space-between">
        <div col="12" sm="8">
          <v-select
            :disabled="roleName == 'Agent'"
            v-model="agentId"
            :items="getUserList"
            item-value="userId"
            item-text="userName"
            label="Users"
            outlined
            dark
            @change="getReport()"
            prepend-icon="mdi-account-box-multiple-outline"
            hide-details=""
          >
          </v-select>
        </div>
        <div col="12" sm="4">
          <v-btn
            class="ml-3 mt-2 add-btn"
            dark
            @click="exportReports"
            :disabled="!accessEnableExport"
          >
            <v-icon>mdi-download</v-icon> Export Report
          </v-btn>
        </div>
      </div>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            Event Registered List
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registrationList"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text.toUpperCase() }}
        </template>
        <template v-slot:[`item.eventStartDate`]="{ item }">
          {{ item.eventStartDate.split(" ")[0] }}
        </template>
        <template v-slot:[`item.eventEndDate`]="{ item }">
          {{ item.eventEndDate.split(" ")[0] }}
        </template>
        <template v-slot:[`item.phoneNumber`]="{ item }">
          {{ phoneMask(item.phoneNumber) }}
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon style="color: #f0ce6a" v-if="item.isActive"
            >mdi-check-bold</v-icon
          >
          <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
        </template>
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="item.imgByte === null"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateHotel('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit Hotel
          </v-tooltip>
        </template> -->
      </v-data-table>
    </v-card>
  </master-layout>
</template>
<script>
import ReportsService from "../services/ReportsService.js";
import UserService from "../services/UserService.js";
import { phoneMasking, byteToFileReader, actionEnable } from "../utils/helpers";
export default {
  components: {},
  name: "ReportsManagement",

  data: () => ({
    loading: false,
    getUserList: [],
    roleName: "",
    agentId: 0,
    registerHeader: [
      { text: "Event Name", value: "eventName", width: 150 },
      { text: "Attendee Name", value: "eventAttendeeName", width: 150 },
      { text: "Allocated Gift", value: "allocatedGiftName", width: 100 },
      { text: "Event Address", value: "eventAddress", width: 120 },
      { text: "Event Start Date", value: "eventStartDate", width: 100 },
      { text: "Event End Date", value: "eventEndDate", width: 100 },
      { text: "Active", value: "isActive", width: 70, sortable: false },
    ],
    registrationList: [],
    search: "",
  }),
  async mounted() {
    this.roleName = this.$store.state.auth.userState.roleName;
    // console.log(this.roleName);
    const fetchAgentList = await UserService.getAgentList();
    this.getUserList = [
      { userName: "All", userId: 0 },
      ...fetchAgentList.data.data,
    ];

    this.agentId = this.$store.state.auth.userState.user.userId;

    this.getReport();
  },
  computed: {
    accessEnableExport() {
      return actionEnable("EventRegisteredReport", "ExportEventRegistered", 1);
    },
  },
  methods: {
    async getReport() {
      try {
        this.loading = true;

        const response = await ReportsService.reportsList({
          attendeeUserId: this.agentId,
        });

        // console.log(res);
        this.registrationList = response.data.data;
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    phoneMask(value) {
      phoneMasking(value);
    },
    async exportReports() {
      try {
        this.loading = true;
        const response = await ReportsService.exportReports({
          attendeeUserId: this.agentId,
          // isActiveEvent: true,
        });
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();

        const fileName = "Event Registered " + `${mm}-${dd}-${yyyy}`;
        byteToFileReader(response.data, fileName, "xlsx");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>
