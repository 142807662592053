import http from "./http-client";

class ReportsService {
  getReportsDetail(payload) {
    return http.get("/Reports/Details", payload);
  }
  updateReports(payload) {
    return http.put("/Reports/Update", payload);
  }
  reportsList(payload) {
    return http.post("/EventAttendee/EventRegistered/", payload);
  }

  exportReports(payload) {
    return http.post("/EventRegisteredReport/ExportEventRegistered", payload, {
      responseType: "blob",
    });
  }
}

export default new ReportsService();
